ul {
  &.inline, &.files, &.sections, &.section-files {
    list-style: none;
    margin-left: 0;
    li {
      list-style: none; } }
  &.inline {
    li {
      display: inline-block;
      list-style: inherit;
      margin-right: rem-calc(20); } } }

hr {
  border-color: #e6e7e8;
  border-style: double;
  border-width: 1px; }


article {
  @include container(90%); }

section {
  padding-top: rem-calc(90);
  padding-bottom: rem-calc(90);
  &.section {
    @include container(90%); } }

aside {
  padding-top: rem-calc(70);
  padding-bottom: rem-calc(70); }

pre code {
  background: $light-gray;
  border: 1px solid $gray;
  display: block;
  height: auto;
  max-height: 200px;
  overflow: scroll;
  padding: rem-calc(20);
  white-space: pre-line; }

//// form styles

label {
  font-size: rem-calc(14);
  cursor: pointer;
  display: block;
  font-weight: bold;
  margin-bottom: 0.5em;
  color: $gray;

  &.error {
    color: $error; } }

input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
textarea,
select {
  background-color: $white;
  border: 1px solid lighten($blue, 40%);
  border-radius: 5px;
  padding: rem-calc(18);
  box-shadow: none;
  display: block;
  margin: 0px 0px rem-calc(18);
  width: 100%;
  font-size: $default-font-size;
  appearance: none;
  cursor: pointer;
  &.error {
    background-color: lighten($error, 65%);
    border: 1px solid $error; }

  &.error + label {
    color: $error; }

  &.success {
    background-color: lighten($success, 65%);
    border: 1px solid $success; }
  &.valid {
    color: $dark-gray; }
  &.auto-width {
    width: auto; } }

input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
select,
textarea {
  color: $dark-gray; }

select {
  background-image: url('../images/chevron-down.png');
  background-position: 90% 50%;
  background-repeat: no-repeat;
  @include breakpoint('screen' $medium) {
    background-position: 96% 50%; }
  &.auto-width {
    background-position: 80% 50%; } }

input[type="checkbox"],
input[type="radio"] {
  display: none; }

input[type="checkbox"] + label span:first-child,
input[type="radio"] + label span:first-child {
  display: inline-block;
  width: 19px;
  height: 19px;
  margin: -1px 10px 0 -5px;
  vertical-align: middle;
  cursor: pointer; }

input[type="radio"] + label span:first-child {
  border-radius: 50%; }

input[type="checkbox"] + label span:first-child,
input[type="radio"] + label span:first-child {
  border: 1px solid lighten($blue, 35%); }

input[type="checkbox"]:checked + label span:first-child,
input[type="radio"]:checked + label span:first-child {
  background-color: $blue;
  border: 1px solid $blue; }
input[type="checkbox"]:checked + label span:first-child:before {
  content: "\f00c";
  color: $white;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  margin-left: 1px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0); }

input[type="file"],
input[type="checkbox"] + label,
input[type="radio"] + label,
select {
  &.error {
    background: lighten($error, 65%); } } // lighten input background since error message is 100% red

select {
  appearance: none; }

select::-ms-expand {
  display: none; }

input[type="text"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
input[type="color"]:focus,
textarea:focus {
  border: 1px solid $blue;
  color: $dark-gray; }


/* Adjust margin for form elements below */
input[type="checkbox"] + label,
input[type="radio"] + label,
input[type="file"] {
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: rem-calc(18);
  vertical-align: baseline; }

textarea {
  resize: vertical; }

fieldset {
  border: 1px solid #DDDDDD;
  background: $white;
  overflow: hidden;
  padding: 1.25rem;
  margin: 1.125rem 0;
  max-height: 10000px;
  transition: max-height 2s;

  legend {
    font-weight: bold;
    background: transparent;
    padding: 0 0.1875rem;
    margin: 0;
    margin-left: -0.1875rem;
    &.ui-element {
      cursor: pointer; } }

  fieldset {
    background: #F9F9F9;

    fieldset {
      background: $white; } }
  &.collapsed {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 500ms; } }

span.error,small.error {
  display: block;
  padding: 0.42857rem 0.64286rem 0.64286rem;
  margin-top: -1px;
  margin-bottom: 1.14286rem;
  font-size: 0.85714rem;
  font-weight: normal;
  font-style: italic;
  background: $error;
  color: $white; }

label {
  small.error {
    display: inline-block;
    font-size: 0.85714rem;
    font-weight: normal;
    font-style: italic;
    background: none;
    color: $error; } }

// errors use red text, red background not needed
label.error {
  background: none; }


// Hidden inputs

input.hidden {
  position: absolute;
  top: 0px;
  left: -99999px; }

i.submit-success {
  color: $success;
  font-size: rem-calc(120); }
