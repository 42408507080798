$white: #ffffff;
.white {
  background-color: $white; }

$dark-gray: #465d75;
.dark-gray {
  background-color: $dark-gray; }

$gray: #b0bec5;
.gray {
  background-color: $gray; }

$light-gray: #efefef;
.light-gray {
  background-color: $light-gray; }

$light-blue: #f3f9ff;
.light-blue {
  background-color: $light-blue; }

$blue: #207edb;
.blue {
  background-color: $blue; }

$dark-blue: #334c65;
.dark-blue {
  background-color: $dark-blue; }

$success: #a4bb54;
.success {
  background-color: $success; }

$warning: #f08a24;
.warning {
  background-color: $warning; }

$error: #8c2e0b;
.error {
  background-color: $error; }
