#login {
  background-color: $dark-blue;
  border-radius: 20px;
  display: block;
  margin: 0px 25%;
  padding: rem-calc(60);
  label {
    color: $white; }
  input.form-submit {
    margin-bottom: 0px; } }
