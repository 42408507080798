.mobile-nav {
  display: none;
  .mobile-menu {
    background-color: $blue;
    .utility-nav {
      padding: 15px 0;
      text-align: center;
      p, ul, span {
        color: $white; }
      p {
        font-size: rem-calc(16);
        margin-bottom: rem-calc(8);
        a {
          color: $white;
          &:hover, &:active, &:focus {
            color: $white; } } }
      ul {
        padding: 0;
        text-align: center;
        li {
          a {
            font-size: rem-calc(16);
            line-height: rem-calc(18); }
          &:last-child {
            margin-right: 0; } } }
      a.button {
        margin: 0.5rem 0;
        padding-left: rem-calc(15);
        padding-right: rem-calc(15);
        &.secondary {
          color: $blue; } } }

    ul {
      list-style: none;
      margin: 0;
      padding: rem-calc(20) 10%;
      li {
        border-bottom: 1px solid rgba(255,255,255,0.1);
        a {
          font-family: $header-font;
          font-size: rem-calc(24);
          color: $white;
          line-height: rem-calc(60);
          &:hover {
            color: $white;
            text-decoration: none; } }
        ul {
          padding: 0px rem-calc(20) rem-calc(20);
          li {
            border-bottom: none;
            a {
              font-size: rem-calc(14);
              line-height: rem-calc(24);
              padding: 0px; } } }
        &:first-child, &:last-child {
          border-bottom: none; } } } }
  @include breakpoint($large) {
    display: none !important; } }
