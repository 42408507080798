// table styles

table {
  width: 100%;
  //border: 1px solid $white
  color: $dark-blue;
  font-family: $primary-font;
  font-size: rem-calc(13);
  font-weight: bold;
  line-height: $default-line-height;
  margin: $default-spacing 0px;
  text-rendering: optimizeLegibility;

  thead {
    tr {
      th {
        color: $dark-gray;
        font-family: $primary-font;
        font-weight: normal;
        text-align: left;
        text-transform: uppercase; } } }

  tbody {
    border-top: none;
    tr {
      border-bottom: solid 1px $light-gray;
      th {
        color: $dark-gray;
        font-weight: normal;
        text-align: left;
        text-transform: uppercase; } } }
  p {
    margin: 0; } }

body.admin {
  table {
    thead {
      tr {
        th {
          background-color: $dark-gray;
          padding: rem-calc(10) rem-calc(15);
          a {
            color: $white;
            font-weight: bold;
            text-transform: uppercase; } } } }
    tbody {
      tr {
        &:nth-child(odd) {
          //border: none
          background: $white; }
        &:nth-child(even) {
          //border: none
          background-color: $light-gray; }
        th {
 }          //padding: rem-calc(10) rem-calc(15)
        td {
          background: transparent;
          padding: rem-calc(10) rem-calc(15);
          a {
            margin: 0px; } } } } }

  .fr-box {
    table {
      thead {
        tr {
          th {
            background-color: transparent;
            color: $dark-gray;
            font-weight: normal;
            text-align: left;
            text-transform: uppercase;
            padding: 0px;
            a {
              color: $dark-blue;
              font-weight: normal;
              text-transform: uppercase; } } } }

      tbody {
        tr {
          &:nth-child(odd) {
            //border: none
            background: transparent; }
          &:nth-child(even) {
            //border: none
            background-color: transparent; }
          th {}
          //padding: rem-calc(10) rem-calc(15)
          td {
            background: transparent;
            padding: 0px; } } } } } }
