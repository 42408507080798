.hero {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('/images/banners/about-banner-small.jpg');
  text-align: center;
  .section {
    padding: rem-calc(120) 0px;
    h1, p {
      color: $white; }
    @include breakpoint('screen' $large) {
      @include container(800px); }
    .breadcrumbs {
      li {
        color: $white;
        font-family: $header-font;
        font-size: rem-calc(20);
        font-weight: normal;
        margin: 0px;
        i {
          margin: rem-calc(15);
          position: relative;
          top: -2px;
          &:before {
            font-size: rem-calc(12); } }
        a {
          color: $white; } } } }

  @include breakpoint('screen' $medium) {
    background-size: cover;
    background-image: url('/images/banners/about-banner-medium.jpg'); }
  @include breakpoint('screen' $large) {
    background-size: cover;
    background-image: url('/images/banners/about-banner-large.jpg'); }
  &.residential {
    background-image: url('/images/banners/residential-banner-small.jpg');
    @include breakpoint('screen' $medium) {
      background-image: url('/images/banners/residential-banner-medium.jpg'); }
    @include breakpoint('screen' $large) {
      background-image: url('/images/banners/residential-banner-large.jpg'); } }
  &.non-residential {
    background-image: url('/images/banners/non-residential-banner-small.jpg');
    @include breakpoint('screen' $medium) {
      background-image: url('/images/banners/non-residential-banner-medium.jpg'); }
    @include breakpoint('screen' $large) {
      background-image: url('/images/banners/non-residential-banner-large.jpg'); } }
  &.safety {
    background-image: url('/images/banners/safety-banner-small.jpg');
    @include breakpoint('screen' $medium) {
      background-image: url('/images/banners/safety-banner-medium.jpg'); }
    @include breakpoint('screen' $large) {
      background-image: url('/images/banners/safety-banner-large.jpg'); } }
  &.about {
    background-image: url('/images/banners/about-banner-small.jpg');
    @include breakpoint('screen' $medium) {
      background-image: url('/images/banners/about-banner-medium.jpg'); }
    @include breakpoint('screen' $large) {
      background-image: url('/images/banners/about-banner-large.jpg'); } }
  &.contact {
    background-image: url('/images/banners/contact-banner-small.jpg');
    @include breakpoint('screen' $medium) {
      background-image: url('/images/banners/contact-banner-medium.jpg'); }
    @include breakpoint('screen' $large) {
      background-image: url('/images/banners/contact-banner-large.jpg'); } }
  &.no-theme {
    background: none;
    text-align: left;
    .section {
      h1, p {
        color: $dark-gray;
        margin-left: 0px;
        margin-right: 0px; } } } }
