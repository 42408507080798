.left {
  float: left !important; }

.right {
  float: right !important; }

.clearfix:before, .clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.hide {
  display: none; }

.invisible {
  visibility: hidden; }

.text-left {
  text-align: left; }

.text-centered {
  text-align: center; }

.text-right {
  text-align: right; }
