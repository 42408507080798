div.search-container {
  position: relative;
  display: none;
  z-index: 999;
  .gsc-input {
    input[type="text"] {
      margin: 0px; } }
  .cse input.gsc-search-button, input.gsc-search-button {
    font-family: $primary-font !important;
    font-size: rem-calc(16) !important;
    font-weight: bold !important;
    height: auto !important;
    line-height: 40px !important;
    margin: rem-calc(18) 0 !important;
    padding-left: rem-calc(20) !important;
    padding-right: rem-calc(20) !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-appearance: none;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px; }

  .gsc-selected-option-container {
    min-width: 80px !important; }

  .gsc-results .gsc-cursor-box .gsc-cursor-current-page {
    border: none;
    background: transparent; }

  .gs-result img.gs-image, .gs-result img.gs-promotion-image {
    border: none; }

  .gsc-clear-button {
    display: none; }

  .gsc-results-wrapper-overlay {
    width: 85% !important;
    left: 7.5% !important; }


  // table styles

  table {
    border: none;
    margin: 0px;
    thead {
      tr {
        border: none;
        th {
          font-family: $primary-font;
          text-align: left;
          padding: 0px;
          height: auto; } } }

    tbody {
      border-top: none;
      padding-top: rem-calc(10);
      padding-bottom: rem-calc(10);
      tr {
        border: none;
        td {
          text-align: left;
          color: $dark-gray; } } } }

  i#search-close {
    color: $blue;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 20px; } }
