// Buttons

button,
a.button,
.button a,
input.form-submit {
  display: inline-block;
  transition: background-color 200ms ease-out 0s;
  color: $blue;
  cursor: pointer;
  font-size: $default-font-size;
  font-weight: bold;
  line-height: $default-line-height;
  margin: rem-calc(18) 0;
  padding: rem-calc(15) rem-calc(24);
  position: relative;
  text-align: center;
  text-decoration: none;
  font-family: $primary-font;
  appearance: none;
  border-radius: 5px;
  &.small {
    font-size: rem-calc(14);
    padding: rem-calc(5) rem-calc(8); }
  &.featured {
    width: 70%;
    @include breakpoint('screen' $medium) {
      width: 80%; } } }

button:hover,
a.button:hover,
.button a:hover,
input:hover.form-submit {
  border: 2px solid $blue;
  text-decoration: none; }

button:active,
a.button:active,
.button a:active,
input:active.form-submit {
  border: 2px solid $blue;
  text-decoration: none; }

button:focus,
a.button:focus,
.button a:focus,
input:focus.form-submit {
  border: 2px solid $blue;
  text-decoration: none; }

button.primary,
a.button.primary,
.button.primary a,
input.form-submit.primary {
  background-color: transparent;
  color: $blue;
  border: 1px solid $blue;

  a {
    color: $blue;
    text-decoration: none; } }

button.primary:hover,
a.button.primary:hover,
.button.primary a:hover,
input:hover.form-submit.primary {
  text-decoration: none;
  border: 2px solid $blue;
  margin-top: 16px;
  margin-left: -2px;
  a {
    text-decoration: none; } }

button.primary:active,
a.button.primary:active,
.button.primary a:active,
input:active.form-submit.primary {
  text-decoration: none;
  border: 2px solid $blue;
  margin-top: 16px;
  margin-left: -2px;
  a {
    text-decoration: none; } }

button.primary:focus,
a.button.primary:focus,
.button.primary a:focus,
input:focus.form-submit.primary {
  text-decoration: none;
  border: 2px solid $blue;
  margin-top: 16px;
  margin-left: -2px;
  a {
    text-decoration: none; } }


button.secondary,
a.button.secondary,
.button.secondary a,
input.form-submit,
form#search-form input.form-submit {
  background-color: $light-gray;
  color: $blue;
  border: 1px solid $light-gray;

  a {
    color: $blue;
    text-decoration: none; } }

button.secondary:hover,
a.button.secondary:hover,
.button.secondary a:hover,
input:hover.form-submit.secondary,
form#search-form input:hover.form-submit {
  background-color: #f8f8f8;
  text-decoration: none;
  color: $blue;
  border: 1px solid #f8f8f8; }

button.secondary:active,
a.button.secondary:active,
.button.secondary a:active,
input:active.form-submit.secondary,
form#search-form input:active.form-submit {
  background-color: #f8f8f8;
  text-decoration: none;
  color: $blue;
  border: 1px solid #f8f8f8; }

button.secondary:focus,
a.button.secondary:focus,
.button.secondary a:focus,
input:focus.form-submit.secondary,
form#search-form input:focus.form-submit {
  background-color: #f8f8f8;
  text-decoration: none;
  color: $blue;
  border: 1px solid #f8f8f8; }

button.ghost,
a.button.ghost,
.button.ghost a,
input.form-submit.ghost {
  background-color: transparent;
  color: $white;
  border: 1px solid $white;
  a {
    color: $white;
    text-decoration: none; } }

button.ghost:hover,
a.button.ghost:hover,
.button.ghost a:hover,
input:hover.ghost.primary {
  background-color: transparent;
  text-decoration: none;
  color: $white;
  border: 2px solid $white;
  margin-top: 16px;
  margin-left: -2px;
  a {
    color: $white;
    text-decoration: none; } }

button.ghost:active,
a.button.ghost:active,
.button.ghost a:active,
input:active.form-submit.ghost {
  background-color: transparent;
  text-decoration: none;
  color: $white;
  border: 2px solid $white;
  margin-top: 16px;
  margin-left: -2px;
  a {
    color: $white;
    text-decoration: none; } }

button.ghost:focus,
a.button.ghost:focus,
.button.ghost a:focus,
input:focus.form-submit.ghost {
  background-color: transparent;
  text-decoration: none;
  color: $white;
  border: 2px solid $white;
  margin-top: 16px;
  margin-left: -2px;
  a {
    color: $white;
    text-decoration: none; } }
