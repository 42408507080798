body {
  font-size: 1em;
  line-height: 1.2em; }

h1, h2, h3 {
  font-family: $header-font;
  text-rendering: optimizelegibility;

  a {
    text-decoration: none; } }

h1, h2, h3, h4 {
  font-weight: bold; }

h1 {
  font-size: rem-calc(35);
  color: $dark-blue;
  font-style: normal;
  margin-bottom: rem-calc(25);
  margin-top: rem-calc(5);
  line-height: rem-calc(40);
  @include breakpoint('screen' $large) {
    font-size: rem-calc(72);
    line-height: rem-calc(80); } }

h2 {
  font-size: rem-calc(32);
  color: $dark-blue;
  font-style: normal;
  margin-bottom: rem-calc(24);
  margin-top: rem-calc(5);
  line-height: rem-calc(40);
  @include breakpoint('screen' $large) {
    font-size: rem-calc(50);
    line-height: rem-calc(56); } }

h3 {
  font-size: rem-calc(28);
  color: $dark-blue;
  font-style: normal;
  margin-bottom: $default-spacing;
  margin-top: rem-calc(5);
  line-height: rem-calc(36); }

h4 {
  font-family: $primary-font;
  font-size: rem-calc(15);
  color: $blue;
  font-style: normal;
  margin-bottom: $default-spacing;
  margin-top: rem-calc(5);
  line-height: rem-calc(18); }

a {
  color: $blue;
  line-height: inherit;
  &:hover,
  &:active {
    color: darken($blue, 20%);
    text-decoration: none;
    & h2, h3, h4, h5 {
      color: $dark-gray; } }
  & h2, h3, h4, h5 {
    color: $dark-gray; } }


p {
  color: $dark-gray;
  font-family: $primary-font;
  font-size: rem-calc(13);
  font-weight: bold;
  line-height: rem-calc(26);
  margin-bottom: $default-spacing;
  text-rendering: optimizeLegibility;
  a {
    font-weight: bold;
    em, i {
      font-family: $primary-font;
      font-style: italic; }
    strong, b {
      font-weight: bold; } } }


ul, ol {
  font-family: $primary-font;
  font-size: rem-calc(13);
  font-weight: normal;
  line-height: $default-line-height;
  margin-left: rem-calc(30);
  margin-bottom: $default-spacing;
  list-style: outside; }

ul {
  list-style-type: disc; }

ol {
  list-style-type: decimal; }

ul ul {
  margin-bottom: 0; }

ul ul:nth-child(even) {
  list-style-type: disc; }

ul ul:nth-child(odd) {
  list-style-type: circle; }

li {
  color: $dark-gray;
  font-weight: bold; }

.no-bullet {
  list-style: outside none none; }

ul.no-bullet {
  margin-left: 0; }

ul, ol {
  &.col {
    columns: 2;
    @include breakpoint('screen' $large) {
      columns: 3; } }
  &.many-col {
    columns: 2;
    @include breakpoint('screen' $medium) {
      columns: 3; }
    @include breakpoint('screen' $large) {
      columns: 4; } } }

ul {
  &.col, &.many-col {
    margin: rem-calc(60) 0px; } }

ul.gallery {
  li {
    padding: rem-calc(20);
    margin-bottom: rem-calc(40);
    @include breakpoint('screen' $medium) {
      @include gallery(12 of 24); }
    @include breakpoint('screen' $large) {
      @include gallery(8 of 24); } } }

.caption p {
  font-size: rem-calc(12);
  font-weight: 600;
  color: lighten($dark-gray, 40%);
  line-height: 1.2;
  padding-top: 1.25em;
  padding-bottom: 0px; }

em,
i {
  font-family: $primary-font;
  font-style: italic;
  line-height: inherit; }

strong,
b {
  color: $blue;
  font-family: $primary-font;
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 75%;
  line-height: inherit; }

sup {
  font-size: 50%; }

blockquote,
blockquote p {
  color: $dark-gray;
  font-family: $primary-font;
  font-size: rem-calc(13);
  font-weight: bold;
  line-height: $default-line-height;
  text-rendering: optimizeLegibility;
  margin: rem-calc(50) 0 rem-calc(50) rem-calc(15);
  padding-top: 0px; }

label {
  color: $dark-gray;
  font-size: rem-calc(13); }

span.registered-name {
  font-variant: small-caps;
  font-size: rem-calc(15); }

