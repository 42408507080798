footer.footer {
  color: $white;
  text-align: center;
  a {
    color: $white;
    &:hover, &:active, &:focus {
      color: $white; } }
  p, span {
    color: $white;
    font-family: $header-font;
    font-weight: bold; }
  ul.inline {
    li {
      margin: 0px rem-calc(10); } }
  #upper-footer {
    padding: rem-calc(40) rem-calc(25);
    p, ul {
      margin-bottom: 0px;
      @include breakpoint('screen' $large) {
        display: inline-block; } }
    p, span {
      font-size: rem-calc(20);
      line-height: rem-calc(26);
      @include breakpoint('screen' $medium) {
        font-size: rem-calc(23);
        line-height: rem-calc(32); } }
    .secondary {
      font-size: rem-calc(12);
      padding: rem-calc(10) rem-calc(15);
      @include breakpoint('screen' $small) {
        font-size: rem-calc(16);
        padding: rem-calc(10) rem-calc(20); } } }
  #lower-footer {
    padding-top: rem-calc(80);
    padding-bottom: rem-calc(70);
    padding-left: rem-calc(25);
    padding-right: rem-calc(25);
    ul {
      &:first-child {
        li {
          margin: 0px;
          padding: 0px rem-calc(15);
          &:first-child {
            border-right: 1px solid $white; } } } }
    p {
      font-size: rem-calc(14);
      @include breakpoint('screen' $medium) {
        font-size: rem-calc(16); } }
    #footer-branding {
      width: 80px;
      height: 60px;
      @include breakpoint('screen' $medium) {
        width: 120px; } }
    #eight-eleven {
      width: 120px;
      height: 60px;
      @include breakpoint('screen' $medium) {
        width: 160px; } } } }



