.primary-billing-section-content {
  @include breakpoint('screen' $large) {
    @include span(12 of 24); } }

.bill-container {
  position: relative;
  @include breakpoint('screen' $large) {
    @include span(12 of 24 at 13); }
  #tooltip {
    background: #468edf;
    border-radius: rem-calc(5);
    cursor: pointer;
    display: none;
    max-width: rem-calc(300);
    padding: rem-calc(20);
    position: absolute;
    h4, p, i {
      color: $white; }
    h4 {
      margin-bottom: 0px;
      text-transform: uppercase; }
    p {
      line-height: rem-calc(16);
      margin: rem-calc(5) 0; }
    i {
      position: absolute;
      top: 5px;
      right: 5px; } } }

