li.has-dropdown {
  position: relative;
  i {
    font-size: rem-calc(10);
    position: relative;
    top: -2px; }
  ul.dropdown {
    background: $white;
    display: none;
    margin-left: 0;
    padding: rem-calc(35) rem-calc(30);
    position: absolute;
    top: 50px;
    left: -5px;
    min-width: 280px;
    -webkit-appearance: none;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    li {
      display: block;
      text-align: left;
      a {
        color: $blue;
        font-family: $header-font;
        font-size: rem-calc(18);
        font-weight: bold;
        line-height: 30px;
        &:hover, &:active, &:focus {
          color: $dark-blue; } }
      .border-top {
        position: absolute;
        width: 33%;
        border-top: 3px solid $gray; }
      &:first-child {
        margin-bottom: rem-calc(35); } } } }

nav#utilityMiddle {
  ul {
    margin-bottom: 0px;
    padding: rem-calc(30);
    text-align: center;
    li {
      a {
        font-size: rem-calc(16);
        line-height: rem-calc(60); } } } }

nav#utilityTop {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  text-align: right;
  p, ul, span {
    color: $white;
    display: inline; }
  span {
    font-size: rem-calc(9); }
  p {
    a {
      color: $white;
      &:hover, &:active, &:focus {
        color: $white; } } }
  ul {
    margin-bottom: 0px;
    text-align: center;
    li {
      a {
        font-size: rem-calc(12);
        line-height: rem-calc(12); }
      &:last-child {
        margin-right: 0; } } }
  a.button {
    margin: 0.5rem 0; } }

nav#secondary-nav {
  ul {
    margin-bottom: 0px;
    li {
      a {
        color: $white;
        font-family: $header-font;
        font-size: rem-calc(16);
        line-height: rem-calc(60); } } } }
.admin-menu {
  @include container(90%);
  ul {
    display: inline-block;
    margin-bottom: 0px;
    li {
      a {
        color: $white;
        display: inline-block;
        margin: rem-calc(10) 0px;
        padding: rem-calc(5) rem-calc(8);
        line-height: rem-calc(22);
        &.button {
          &:hover {
            margin-top: rem-calc(8);
            margin-left: -2px; } } } }
    &:last-child {
      float: right; } } }
