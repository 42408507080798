body.admin {
  background-color: #F9F9F9;

  .left-col {
    @include breakpoint('screen' $medium) {
      @include span(16); } }

  .right-col {
    @include breakpoint('screen' $medium) {
      @include span(8 at 17); } }

  #mtcpu_pagebundle_page_children {
    input[type="checkbox"] + label {
      display: block;
      font-size: rem-calc(10); } } }
