header.header {
  @include container(95%);
  @include breakpoint('screen' $small) {
    @include container(90%); }
  ul {
    float: right;
    list-style: none;
    margin: 0px;
    margin-top: 10px;
    @include breakpoint('screen' $medium) {
      margin-top: 20px; }
    @include breakpoint('screen' $large) {
      margin-top: 10px; }
    li {
      display: inline-block;
      margin: 0px rem-calc(10);
      a {
        font-family: $header-font;
        &.section {
          font-size: rem-calc(18);
          line-height: rem-calc(75);
          @include breakpoint('screen' 1225px) {
            font-size: rem-calc(20);
            margin: 0px rem-calc(5); } }
        &#mm-trigger {
          border: 1px solid lighten($blue, 30%);
          border-radius: 5px;
          display: block;
          height: 45px;
          padding: rem-calc(5) rem-calc(10);
          i {
            &:before {
              color: lighten($blue, 30%);
              position: relative;
              top: 4px; } } }
        &#search {
          padding: 0px rem-calc(10);
          i {
            line-height: 0px; } }
        &.secondary {
          padding-top: rem-calc(5);
          padding-bottom: rem-calc(5);
          img {
            height: 27px; } } } } }


  #branding {
    float: left;
    margin-top: 10px;
    width: 100px;
    @include breakpoint('screen' $small) {
      margin-top: 5px;
      width: 150px; } }

  @include breakpoint('screen' $large) {
    @include container(960px); } }
