@import 'fa_vars';

@font-face {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: 900;
  src: url('#{$fa-font-path}/fontawesome-webfont.eot');
  src: url('#{$fa-font-path}/fontawesome-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$fa-font-path}/fontawesome-webfont.woff2') format('woff2'),
  url('#{$fa-font-path}/fontawesome-webfont.woff') format('woff'),
  url('#{$fa-font-path}/fontawesome-webfont.ttf') format('truetype'),
  url('#{$fa-font-path}/fontawesome-webfont.svg#fontawesome') format('svg');
}

.fa,
.fas {
  font-family: 'FontAwesome';
  font-weight: 900;
}