ul.cards {
  list-style: none;
  margin-left: 0px;
  li {
    border: 2px solid lighten($blue, 35%);
    border-radius: 5px;
    margin-bottom: rem-calc(20);
    padding: rem-calc(45) rem-calc(40);
    h3 {
      margin-top: 0px; }
    .button {
      margin-top: rem-calc(30);
      margin-bottom: 0px;
      width: 100%;
      &:hover, &:focus, &:active {
        margin-top: rem-calc(28); } }
    @include breakpoint('screen' $medium) {
      @include gallery(6 of 12); } }
  &.many {
    li {
      @include breakpoint('screen' $medium) {
        @include gallery(6 of 12); }
      @include breakpoint('screen' $large) {
        @include gallery(8 of 24); } } } }

div.card {
  border: 2px solid lighten($blue, 35%);
  border-radius: 5px;
  padding: rem-calc(45) rem-calc(40);
  h3 {
    margin-top: 0px; }
  .button {
    margin-top: rem-calc(34);
    margin-bottom: 0px;
    width: 100%;
    &:hover, &:focus, &:active {
      margin-top: rem-calc(32); } }
  @include breakpoint('screen' 800px) {
    @include span(10 at 15); }
  @include breakpoint('screen' $large) {
    @include span(8 at 17); } }
