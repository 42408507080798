/// Strips the unit from a number.
///
/// @param {Number (With Unit)} $value
///
/// @example scss - Usage
///   $dimension: strip-units(10em);
///
/// @example css - CSS Output
///   $dimension: 10;
///
/// @return {Number (Unitless)}

@function strip-units($value) {
  @return ($value / ($value * 0 + 1));
}

@function rem-calc($pxval) {

  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }

  $base: $em-base;
  @if not unitless($base) {
    $base: strip-units($base);
  }

  @return ($pxval / $base) * 1rem;
}
