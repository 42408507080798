@include breakpoint('screen' (min-width 0) (max-width ($medium - 1px)) ) {
  .hide-for-small-only {
    display: none !important; } }

@include breakpoint('screen' $medium) {
  .show-for-small-only {
    display: none !important; } }

@include breakpoint('screen' $medium) {
  .hide-for-medium {
    display: none !important; } }

@include breakpoint('screen' (max-width $medium - 1px)) {
  .show-for-medium {
    display: none !important; } }

@include breakpoint('screen' (min-width $medium)(max-width $large - 1px)) {
  .hide-for-medium-only {
    display: none !important; } }

@include breakpoint('screen' (max-width $medium - 1px)(min-width $large)) {
  .show-for-medium-only {
    display: none !important; } }

@include breakpoint('screen' $large) {
  .hide-for-large {
    display: none !important; } }

@include breakpoint('screen' (max-width ($large - 1px))) {
  .show-for-large {
    display: none !important; } }
