@import "../node_modules/susy/sass/susy";
@import "../node_modules/breakpoint-sass/stylesheets/breakpoint";

@import "base/foundation";
@import "base/susy_config";
@import "fonts/fa-solid";
@import "fonts/font-awesome";
@import "base/functions";

$header-font:  "proxima-nova-condensed", Impact, Trebuchet, sans-serif;
$primary-font: Helvetica, Arial, sans-serif;

$em-base: 16;
$default-font-size: rem-calc(16);
$default-line-height: rem-calc(22);
$default-spacing: rem-calc(16);

$default-radius: rem-calc(15);

$small: 400px;
$medium: 600px;
$large: 1024px;

@import "base/colors";
@import "base/elements";
@import "base/tables";
@import "base/typography";
@import "base/utility";
@import "base/visibility";

@import "components/accents";
@import "components/buttons";
@import "components/calculators";
@import "components/call_outs";
@import "components/card";
@import "components/froala";
@import "components/hero";
@import "components/login";
@import "components/pagination";
@import "components/search";

@import "layout/base";
@import "layout/footer";
@import "layout/header";
@import "layout/mobile_nav";
@import "layout/nav";

@import "sections/admin";
@import "sections/billing";
@import "sections/root";
