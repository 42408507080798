// pagination styles

ul.pagination {
  display: block;
  min-height: 1.71429rem;
  margin-left: -0.35714rem; }

ul.pagination li {
  height: 1.71429rem;
  color: $blue;
  font-size: 1rem;
  margin-left: 0; }

ul.pagination li a,
ul.pagination li button {
  display: block;
  padding: 0.07143rem 0.5rem;
  color: $blue;
  background: none;
  border-radius: 3px;
  font-weight: normal;
  font-size: 1em;
  line-height: inherit;
  transition: background-color 300ms ease-out; }

ul.pagination li:hover a,
ul.pagination li a:focus,
ul.pagination li:hover button,
ul.pagination li button:focus {
  background: $blue;
  color: $white; }

ul.pagination li.unavailable a,
ul.pagination li.unavailable button {
  cursor: default;
  color: $blue; }

ul.pagination li.unavailable:hover a,
ul.pagination li.unavailable a:focus,
ul.pagination li.unavailable:hover button,
ul.pagination li.unavailable button:focus {
  background: $blue;
  color: $white; }

ul.pagination li.current a,
ul.pagination li.current button {
  background: none;
  color: $dark-gray;
  font-weight: bold;
  cursor: default; }

ul.pagination li.arrow a,
ul.pagination li.arrow button {}
ul.pagination li.arrow a,
ul.pagination li.arrow i {
  font-weight: bold; }

//ul.pagination li.current a:hover,
//ul.pagination li.current a:focus,
//ul.pagination li.current button:hover,
//ul.pagination li.current button:focus
////background: $light-blue
//////color: $white

ul.pagination li {
  float: left;
  display: block; }

.pagination-centered {
  text-align: center; }

.pagination-centered ul.pagination li {
  float: none;
  display: inline-block; }

ul.pagination a {
  text-decoration:  none; }
