ul.call-outs {
  list-style: none;
  margin-left: 0px;
  li {
    padding: rem-calc(20);
    @include breakpoint('screen' $medium) {
      @include gallery(6 of 12); }
    .button {
      margin: 0px;
      width: 100%; }
    .primary {
      margin-top: rem-calc(40);
      &:hover, &:active, &:focus {
        margin-top: rem-calc(38);
        margin-left: -2px; } } } }
